export const colors = {
  0: '#2baf60',
  1: '#fccc0a',
  2: '#7981c7',
  3: '#0a9fd9',
  4: '#d61c60',
  5: '#35d835',
  6: '#f9880d',
  7: '#24d3c2',
  8: '#6d29ce',
  9: '#f445e8',
};
