import { AlertColor } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Certification } from '@cdiverse/cd-search';

import { Notification } from '@models/appLayout/appLayout.interface';
import { LandingPageInfo } from '@models/landing/Landing.interface';
import { CompanyProfile } from '@models/common/company.interface';

export interface LanguageState {
  side_bar_buttons: Record<string, unknown>;
  main_menu: Record<string, unknown>;
  dashboard: Record<string, unknown>;
  search_suppliers: Record<string, unknown>;
  supplier_detail: Record<string, unknown>;
  certification_detail: Partial<
    Certification & {
      CertificationType: string;
      Ethnicity: string;
      CertificationNumber: string;
      ExpirationDate: string;
      EffectiveDate: string;
      CertificationProvider: string;
      CertificationDetail: string;
      Close: string;
    }
  >;
  company_profile: Partial<CompanyProfile>;
  user_management: Partial<{
    FirstName: string;
    LastName: string;
    Email: string;
    UserRole: string;
    Status: string;
    InviteUser: string;
  }>;
  mailing_list: {
    NewListName?: string;
    Create?: string;
    Cancel?: string;
  };
  my_profile: Partial<{
    AddYourSocialMedia: string;
    Update: string;
    Email: string;
    LightDarkTheme: string;
    Current: string;
  }>;
  venlytics: Record<string, unknown>;
  campaign_button: Record<string, unknown>;
  venpro: Record<string, unknown>;
  campaign: Record<string, unknown>;
}

export interface CurrentAuditList {
  id: number;
  supplierIDs: number[];
}

export interface MapSlice {
  user: {
    isLoggedIn: boolean;
    username: string;
    email: string;
    user_role?: number;
    phone_number?: number;
    phone_extension?: number;
  };
  languageState: LanguageState;
  landingState: Partial<LandingPageInfo>;
  notifications: Notification[];
  mainSnackbarOpen: boolean;
  mainSnackbarMessage: string;
  mainSnackbarType: AlertColor;
  currentAuditList: CurrentAuditList;
}

export interface SetAppUserAttributes {
  email: string;
  phone_number: number;
  'custom:userrole': number;
  'custom:extension': number;
}

export interface SetAppUserPayload {
  username: string;
  attributes: SetAppUserAttributes;
  token: string;
}

export interface OpenSnackbarPayload {
  mainSnackbarOpen: boolean;
  mainSnackbarMessage: string;
  mainSnackbarType: AlertColor;
}

const initialState: MapSlice = {
  user: {
    isLoggedIn: false,
    username: '',
    email: '',
    user_role: undefined,
    phone_number: undefined,
    phone_extension: undefined,
  },
  notifications: [],
  languageState: {
    side_bar_buttons: {},
    main_menu: {},
    dashboard: {},
    search_suppliers: {},
    supplier_detail: {},
    certification_detail: {},
    company_profile: {},
    user_management: {},
    mailing_list: {},
    my_profile: {},
    venlytics: {},
    campaign_button: {},
    venpro: {},
    campaign: {},
  },
  landingState: {},
  mainSnackbarOpen: false,
  mainSnackbarMessage: '',
  mainSnackbarType: 'success',
  currentAuditList: {
    id: 0,
    supplierIDs: [],
  },
};

export const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setAppUser: (state, action: PayloadAction<SetAppUserPayload>) => {
      state.user = {
        isLoggedIn: true,
        username: action.payload.username,
        email: action.payload.attributes.email,
        user_role: +action.payload.attributes['custom:userrole'],
        phone_number: action.payload.attributes.phone_number,
        phone_extension: action.payload.attributes['custom:extension'],
      };
    },
    clearAppUser: (state) => {
      state.user = {
        isLoggedIn: false,
        username: '',
        email: '',
        user_role: undefined,
        phone_number: undefined,
        phone_extension: undefined,
      };
    },
    openSnackbar: (state, action: PayloadAction<OpenSnackbarPayload>) => {
      state.mainSnackbarOpen = action.payload.mainSnackbarOpen;
      state.mainSnackbarMessage = action.payload.mainSnackbarMessage;
      state.mainSnackbarType = action.payload.mainSnackbarType;
    },
    closeSnackbar: (state) => {
      state.mainSnackbarOpen = false;
      state.mainSnackbarMessage = '';
      state.mainSnackbarType = 'success';
    },
    setLanguageState: (state, action: PayloadAction<LanguageState>) => {
      state.languageState = action.payload;
    },
    setNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.notifications = action.payload;
    },
    setCurrentAuditIDList: (state, action: PayloadAction<CurrentAuditList>) => {
      state.currentAuditList = action.payload;
    },
    setLandingState: (
      state,
      action: PayloadAction<Record<string, unknown>>
    ) => {
      state.landingState = action.payload;
    },
  },
});

export const {
  setAppUser,
  clearAppUser,
  openSnackbar,
  closeSnackbar,
  setLanguageState,
  setNotifications,
  setCurrentAuditIDList,
  setLandingState,
} = appStateSlice.actions;

export const appStateReducer = appStateSlice.reducer;
