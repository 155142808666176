import { combineReducers } from '@reduxjs/toolkit';

import { createAsyncSlice } from '@common/store/helpers';

import { AmplifyLoginData, AwsSignInResponseData } from './auth.types';

export const loginSlice = createAsyncSlice<
  AmplifyLoginData,
  AwsSignInResponseData | undefined,
  string
>({ name: 'login' });

export const authReducer = combineReducers({
  login: loginSlice.reducer,
});
