import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  MapSlice,
  Location,
  MapCoordinate,
} from '../../models/map/Map.interface';

const initialState: MapSlice = {
  center: { lat: 39.8283, lng: -98.5795 },
  zoom: 4,
  coordinates: [],
  pinType: 'cluster',
  showWindow: false,
};

export const mapSlice = createSlice({
  name: 'mapState',
  initialState,
  reducers: {
    setMap: (state, action: PayloadAction<MapSlice>) => {
      state = action.payload;
    },
    updateCenterZoomType: (
      state,
      action: PayloadAction<{
        center: Location;
        zoom: number;
        pinType: string;
      }>
    ) => {
      state.center = action.payload.center;
      state.zoom = action.payload.zoom;
      state.pinType = action.payload.pinType;
    },
    updateCoordinates: (state, action: PayloadAction<MapCoordinate[]>) => {
      state.coordinates = action.payload;
    },
    updatePinType: (state, action: PayloadAction<string>) => {
      state.pinType = action.payload;
    },
    showWindow: (state, action: PayloadAction<boolean>) => {
      state.showWindow = action.payload;
    },
  },
});

export const {
  setMap,
  updateCenterZoomType,
  updateCoordinates,
  updatePinType,
  showWindow,
} = mapSlice.actions;

export const mapReducer = mapSlice.reducer;
