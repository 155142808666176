import { configureStore } from '@reduxjs/toolkit';

import { sagaMiddleware } from '@common/store/middlewares';

import { reducer } from './reducer';

export const store = configureStore({
  reducer,
  middleware: [sagaMiddleware],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
