import React, { useEffect, Suspense } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import axios, { AxiosError } from 'axios';
import { isExpired } from 'react-jwt';
import { Route, Switch, useHistory, HashRouter } from 'react-router-dom';
import { Ripple } from '@progress/kendo-react-ripple';

import loadable from '@loadable/component';
import { useAppDispatch } from '@common/hooks/useAppDispatch';
//import React, { useEffect, Suspense } from 'react';
import awsConfig from './config/aws-exports';
import {
  clearAppUser,
  setAppUser,
  SetAppUserAttributes,
} from './ducks/appState/appState.slice';
import './App.scss';
import Fallback from './components/supports/Fallback';
import ScrollToTop from './components/supports/ScrollToTop';

export interface AuthUser {
  signInUserSession: {
    accessToken: {
      jwtToken: string;
    };
  };
  attributes: SetAppUserAttributes;
  username: string;
}

const landing = loadable(
  () => import('./components/layouts/landing/LandingLayout'),
  {
    fallback: <Fallback />,
  }
);
const auth = loadable(() => import('./components/layouts/auth/AuthLayout'), {
  fallback: <Fallback />,
});
const app = loadable(() => import('./components/layouts/app/AppLayout'), {
  fallback: <Fallback />,
});

function App() {
  Amplify.configure(awsConfig);
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user: AuthUser) => {
        const token = user.signInUserSession.accessToken.jwtToken;
        const isMyTokenExpired = isExpired(token);
        if (isMyTokenExpired) {
          localStorage.clear();
          dispatch(clearAppUser());
          history.replace('/auth/signin');
        } else {
          (
            axios.defaults.headers as { [key: string]: string }
          ).Authorization = `Bearer ${token}`;
          axios.interceptors.response.use(
            (response) => {
              return response;
            },
            (error) => {
              if ((error as AxiosError).response?.status === 401) {
                dispatch(clearAppUser());
                localStorage.clear();
              }
              return Promise.reject(error);
            }
          );
          dispatch(
            setAppUser({
              username: user.username,
              attributes: user.attributes,
              token,
            })
          );
        }
      })
      .catch((err) => {
        console.error('auth.currentAuthenticatedUser:', err);
        dispatch(clearAppUser());
        localStorage.clear();
      });
  }, [dispatch, history]);

  return (
    <HashRouter>
      <ScrollToTop>
        <Suspense fallback="">
          <Switch>
            <Ripple>
              <Route exact path="/" component={landing} />
              <Route path="/landing" component={landing} />

              <Route path="/auth" component={process.env.REACT_APP_ENV==='upmc'?landing:auth} />
              <Route path="/app" component={process.env.REACT_APP_ENV==='upmc'?landing:app} />
            </Ripple>
          </Switch>
        </Suspense>
      </ScrollToTop>
    </HashRouter>
  );
}

export default App;
