import axios from 'axios';
import { SourceSearchParams } from '@cdiverse/dashboard-lib';
import { ISearchResult } from '@cdiverse/cd-search';

export const searchApi = {
  getSearchSupplierResults: (params: SourceSearchParams) =>
    axios.get<ISearchResult>('api/v1/search/supplier', { params }),

  getGlobalSearchResults: (params: SourceSearchParams) =>
    axios.get<ISearchResult>('api/v1/search/globalsupplier', { params }),
};
