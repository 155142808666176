export const auditStatusList = [
  { label: 'Unvetted', value: 1 },
  { label: 'Approved', value: 2 },
  { label: 'Suspended', value: 3 },
  { label: 'Awaiting Approval', value: 4 },
  { label: 'Partially Vetted', value: 5 },
];

export const registrationStatusList = [
  { label: 'Unregistered', value: 1 },
  { label: 'Partially Registered', value: 2 },
  { label: 'Fully Registered', value: 3 },
];

export const vettingStatus = [
  { category: 'approved', label: 'Approved' },
  { category: 'unvetted', label: 'Unvetted' },
  { category: 'awaiting_approval', label: 'Awaiting Approval' },
  { category: 'suspended', label: 'Suspended' },
  { category: 'partially_vetted', label: 'Partially Vetted' },
];

export const registrationStatus = [
  { category: 'fully_registered', label: 'Fully Registered' },
  { category: 'partially_registered', label: 'Partially Registered' },
  { category: 'unregistered', label: 'Unregistered' },
];

export const serviceTypes = [
  { category: 'both', label: 'Both' },
  { category: 'product_goods', label: 'Products/Goods' },
  { category: 'professional_services', label: 'Professional Services' },
];

export const certificationTypes = [
  { category: 'DBE', label: 'DBE' },
  { category: 'HUB', label: 'HUB' },
  { category: 'LGBTBE', label: 'LGBTBE' },
  { category: 'MBE', label: 'MBE' },
  { category: 'SBA_8a', label: 'SBA 8(a)' },
  { category: 'SDB', label: 'SDB' },
  { category: 'VBE', label: 'VBE' },
  { category: 'VOSB', label: 'VOSB' },
  { category: 'WBE', label: 'WBE' },
  { category: 'custom', label: 'Custom' },
];
