import { searchReducer } from '@ducks/search/search.slice';
import { accessPolicyReducer } from '@ducks/accessPolicy/accessPolicy.slice';
import { appStateReducer } from '@ducks/appState/appState.slice';
import { mainStateReducer } from '@ducks/mainState/mainState.slice';
import { mapReducer } from '@ducks/map/map.slice';
import { authReducer } from '@ducks/auth/auth.slice';

export const reducer = {
  appState: appStateReducer,
  mainState: mainStateReducer,
  access_policy: accessPolicyReducer,
  mapState: mapReducer,
  search: searchReducer,
  auth: authReducer,
};
