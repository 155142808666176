import { call, put, takeEvery } from '@redux-saga/core/effects';
import { Auth } from 'aws-amplify';
import axios, { AxiosError } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

import { clearAppUser, setAppUser } from '@ducks/appState/appState.slice';
import { store } from '@store';

import { loginSlice } from './auth.slice';
import { AmplifyLoginData, AwsSignInResponseData } from './auth.types';

function* loginSaga({ payload }: PayloadAction<AmplifyLoginData>) {
  try {
    const userAuthData: AwsSignInResponseData = (yield call(
      (username: string, password: string) => Auth.signIn(username, password),
      payload.username,
      payload.password
    )) as AwsSignInResponseData;

    if (userAuthData.challengeName !== 'NEW_PASSWORD_REQUIRED') {
      const token = userAuthData.signInUserSession.accessToken?.jwtToken;
      (
        axios.defaults.headers as { Authorization: string }
      ).Authorization = `Bearer ${token || ''}`;

      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error: AxiosError) => {
          if (error.response?.status === 401) {
            store.dispatch(clearAppUser());
            localStorage.clear();
            return null;
          }

          return Promise.reject(error);
        }
      );

      if (!userAuthData.username || !userAuthData.attributes || !token) return;

      yield put(
        setAppUser({
          username: userAuthData.username,
          attributes: userAuthData.attributes,
          token,
        })
      );

      yield put(loginSlice.actions.success());
    } else {
      yield put(loginSlice.actions.success(userAuthData));
    }
  } catch (error) {
    yield put(loginSlice.actions.error((error as AxiosError).message));
  }
}

export function* authSagas() {
  yield takeEvery(loginSlice.actions.request, loginSaga);
}
