import React, { useEffect } from 'react';
import { StaticContext } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const ScrollToTop: React.FC<
  RouteComponentProps<Record<string, never>, StaticContext, unknown>
> = ({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <>{children || null}</>;
};

export default withRouter(ScrollToTop);
