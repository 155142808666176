import { ISearchResult } from '@cdiverse/cd-search';
import { SourceSearchParams } from '@cdiverse/dashboard-lib';
import { combineReducers } from '@reduxjs/toolkit';

import { createAsyncSlice } from '@common/store/helpers';

export const getAuditSearchResultsSlice = createAsyncSlice<
  SourceSearchParams | undefined,
  ISearchResult,
  unknown
>({
  name: 'getAuditSearchResults',
});

export const getSourceSearchResultsSlice = createAsyncSlice<
  SourceSearchParams | undefined,
  ISearchResult,
  unknown
>({
  name: 'getSourceSearchResults',
});

export const getGlobalSearchResultsSlice = createAsyncSlice<
  SourceSearchParams | undefined,
  ISearchResult,
  unknown
>({
  name: 'getGlobalSearchResults',
});

export const getInitialSourceSearchChartsAndMapSlice = createAsyncSlice<
  undefined,
  undefined,
  unknown
>({
  name: 'getInitialSourceSearchChartsAndMap',
});

export const getInitialAuditSearchChartsAndMapSlice = createAsyncSlice<
  undefined,
  undefined,
  unknown
>({
  name: 'getInitialAuditSearchChartsAndMap',
});

export const getInitialGlobalSearchChartsAndMapSlice = createAsyncSlice<
  undefined,
  undefined,
  unknown
>({
  name: 'getInitialGlobalSearchChartsAndMap',
});

export const searchReducer = combineReducers({
  getSourceSearchResults: getSourceSearchResultsSlice.reducer,
  getAuditSearchResults: getAuditSearchResultsSlice.reducer,
  getGlobalSearchResults: getGlobalSearchResultsSlice.reducer,
  getInitialSourceSearchChartsAndMap:
    getInitialSourceSearchChartsAndMapSlice.reducer,
  getInitialAuditSearchChartsAndMap:
    getInitialAuditSearchChartsAndMapSlice.reducer,
  getInitialGlobalSearchChartsAndMap:
    getInitialGlobalSearchChartsAndMapSlice.reducer,
});
