import React from 'react';
import { Loader } from '@progress/kendo-react-indicators';

const Fallback = () => {
  return (
    <div
      style={{
        position: 'fixed',
        display: 'block',
        zIndex: 1000000,
        right: 15,
        top: 15,
      }}
    >
      <Loader themeColor="secondary" />
    </div>
  );
};

export default Fallback;
