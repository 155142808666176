import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AccessPolicy } from '@models/userManagement/UserManagement.interface';

export interface AccessPolicyState {
  f_source_allowed?: boolean;
  f_audit_allowed?: boolean;
  f_reports_allowed?: boolean;
  f_export_allowed?: boolean;
  f_manage_allowed?: boolean;
  r_company_profile_allowed?: boolean;
  r_company_profile_operations?: string;
  a_tax_id_allowed?: boolean;
  a_tax_id_operations?: string;
  a_test_attribute_3_allowed?: boolean;
  a_test_attribute_3_operations?: string;
  r_user_management_allowed?: boolean;
  r_user_management_operations?: string;
  r_user_profile_allowed?: boolean;
  r_user_profile_operations?: string;
  r_message_allowed?: boolean;
  r_message_operations?: string;
  r_tasks_allowed?: boolean;
  r_tasks_operations?: string;
  r_custom_audit_list_allowed?: boolean;
  r_custom_audit_list_operations?: string;
  r_rbac_allowed?: boolean;
  r_rbac_operations?: string;
  r_supplier_profile_allowed?: boolean;
  r_supplier_profile_operations?: string;
  a_supplier_tax_id_allowed?: boolean;
  a_supplier_tax_id_operations?: string;
  r_search_allowed?: boolean;
  r_search_operations?: string;
  a_keywords_allowed?: boolean;
  a_keywords_operations?: string;
  a_search_by_audit_status_allowed?: boolean;
  a_search_by_audit_status_operations?: string;
  a_search_by_registration_status_allowed?: boolean;
  a_search_by_registration_status_operations?: string;
  a_search_by_certification_type_allowed?: boolean;
  a_search_by_certification_type_operations?: string;
  a_search_by_ethnicity_allowed?: boolean;
  a_search_by_ethnicity_operations?: string;
  a_search_by_expiration_dates_allowed?: boolean;
  a_search_by_expiration_dates_operations?: string;
  a_search_by_registration_date_allowed?: boolean;
  a_search_by_registration_date_operations?: string;
  a_search_by_business_categories_allowed?: boolean;
  a_search_by_business_categories_operations?: string;
  a_search_by_naics_codes_allowed?: boolean;
  a_search_by_naics_codes_operations?: string;
  a_search_by_sic_codes_allowed?: boolean;
  a_search_by_sic_codes_operations?: string;
  a_search_by_service_type_allowed?: boolean;
  a_search_by_service_type_operations?: string;
  [key: string]: string | boolean | undefined;
}

export type AccessPolicyStateKeys = keyof AccessPolicyState;

const initialState: AccessPolicyState = {};

export const accessPolicySlice = createSlice({
  name: 'accessPolicyState',
  initialState,
  reducers: {
    setInitAccessPolicy: (state, action: PayloadAction<AccessPolicy>) => {
      const temp: AccessPolicyState = {};

      action.payload.features.forEach((a) => {
        temp[`f_${a.key}_allowed` as keyof typeof temp] = a.allowed;
      });

      action.payload.resources.forEach((a) => {
        temp[`r_${a.key}_allowed` as keyof typeof temp] = a.allowed;
        let resourceOperations = '';
        resourceOperations += a.operations.createAllowed ? '1' : '';
        resourceOperations += a.operations.readAllowed ? '2' : '';
        resourceOperations += a.operations.updateAllowed ? '3' : '';
        resourceOperations += a.operations.deleteAllowed ? '4' : '';
        temp[`r_${a.key}_operations`] = resourceOperations;

        a.attributes.forEach((b) => {
          temp[`a_${b.key}_allowed` as keyof typeof temp] = b.allowed;
          let AttrOperations = '';
          AttrOperations += b.operations?.readAllowed ? '2' : '';
          AttrOperations += b.operations?.updateAllowed ? '3' : '';
          temp[`a_${b.key}_operations` as keyof typeof temp] = AttrOperations;
        });
      });

      return temp;
    },
  },
});

export const { setInitAccessPolicy } = accessPolicySlice.actions;

export const accessPolicyReducer = accessPolicySlice.reducer;
