import { Company, ISearchResult } from '@cdiverse/cd-search';
import { SourceSearchParams } from '@cdiverse/dashboard-lib';
import { call, put, takeEvery, select } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { searchApi } from '@api/search';
import {
  serviceTypes,
  vettingStatus,
  certificationTypes,
  registrationStatus,
} from '@common/constants/pieCharts';
import { colors } from '@common/constants/colors';
import {
  setAuditStatusPie,
  setCertificationTypePie,
  setRegistrationStatusPie,
  setServiceTypePie,
} from '@ducks/mainState/mainState.slice';
import { MapCoordinate } from '@models/map/Map.interface';
import { updateCenterZoomType, updateCoordinates } from '@ducks/map/map.slice';
import { RootState } from '@store';
import { PieChartItem } from '@models/appLayout/appLayout.interface';

import {
  getAuditSearchResultsSlice,
  getGlobalSearchResultsSlice,
  getInitialAuditSearchChartsAndMapSlice,
  getInitialGlobalSearchChartsAndMapSlice,
  getInitialSourceSearchChartsAndMapSlice,
  getSourceSearchResultsSlice,
} from './search.slice';

export type SetPieChartActionCreator = (payload: PieChartItem[]) => {
  payload: PieChartItem[];
  type: string;
};

// Source search page initital request: enterprise param = uuid, size = 0, from = 0; aggregate_by = '';
// Audit search page initital request: enterprise param = uuid, size = 0, from = 0; aggregate_by = '';
// Global search page initital request: enterprise param = '', size = 0, from = 0; aggregate_by = '';

// Source search page charts 2nd request: enterprise param = uuid, size = res.data.Total, from = 0; aggregate_by = 'service_type,certification_type';
// Audit search page charts 2nd request: enterprise param = uuid, size = res.data.Total, from = 0; aggregate_by = 'audit_status,registration_status';
// Global search page charts 2nd request: enterprise param = '', size = res.data.Total, from = 0; aggregate_by = 'audit_status,registration_status';

const getChartsData = <T>(
  data: T,
  pieChartOptions: {
    category: string;
    label: string;
  }[]
) => {
  return pieChartOptions.map((a, index) => {
    return {
      category: a.category,
      label: a.label,
      value: data[a.category as keyof typeof data],
      color: colors[index as keyof typeof colors],
    };
  });
};

function* setSearchChartsDataSaga(
  fieldsToFillWith: (keyof ISearchResult)[],
  data: ISearchResult
) {
  const [leftField, rightField] = fieldsToFillWith;

  const chartActionsByField: {
    [key in keyof ISearchResult]?: SetPieChartActionCreator;
  } = {
    audit_status_stats: setAuditStatusPie,
    registration_status_stats: setRegistrationStatusPie,
    certification_type_stats: setCertificationTypePie,
    service_type_stats: setServiceTypePie,
  };

  const chartDataByField: {
    [key in keyof ISearchResult]?: {
      category: string;
      label: string;
    }[];
  } = {
    audit_status_stats: vettingStatus,
    registration_status_stats: registrationStatus,
    certification_type_stats: certificationTypes,
    service_type_stats: serviceTypes,
  };

  if (!data[leftField] || !data[rightField]) return;

  const leftChartData = getChartsData(
    data[leftField],
    chartDataByField[leftField] as {
      category: string;
      label: string;
    }[]
  );

  const rightChartData = getChartsData(
    data[rightField],
    chartDataByField[rightField] as {
      category: string;
      label: string;
    }[]
  );

  const leftChartAction = chartActionsByField[leftField];
  const rightAction = chartActionsByField[rightField];

  if (leftChartAction) {
    yield put(leftChartAction(leftChartData));
  }

  if (rightAction) {
    yield put(rightAction(rightChartData));
  }
}

function* updateMapSaga(companies: Company[], from = 1) {
  const coordinates: MapCoordinate[] = [];
  if (companies === null) return;

  companies.forEach((a, index) => {
    if (
      a.location !== undefined &&
      a.location.lat !== 0 &&
      a.location.lon !== 0
    ) {
      coordinates.push({
        company: a,
        name: a.name,
        pinID: from + index + 1,
        location: { lat: a.location.lat, lng: a.location.lon } || {
          lat: 0,
          lng: 0,
        },
        address: `${a.address}, ${a.city}, ${a.state}, ${a.zipcode}`,
      });
    } else {
      coordinates.push({
        company: a,
        name: a.name,
        pinID: from + index + 1,
        location: { lat: 33.969528, lng: -84.261581 },
        address: `${a.address}, ${a.city}, ${a.state}, ${a.zipcode}`,
      });
    }
  });

  yield put(
    updateCenterZoomType({
      center: { lat: 39.8283, lng: -98.5795 },
      zoom: 4,
      pinType: 'cluster',
    })
  );
  yield put(updateCoordinates(coordinates));
}

function* getSearchMapInitialDataSaga(
  params: SourceSearchParams,
  chartsFields: (keyof ISearchResult)[],
  api?: (params: SourceSearchParams) => Promise<AxiosResponse<ISearchResult>>
): Generator<unknown, void> {
  const selectedCoordinates = (yield select(
    (state: RootState) => state.mapState.coordinates
  )) as MapCoordinate[];

  const apiToCall = api || searchApi.getSearchSupplierResults;

  const res = (yield call(apiToCall, params)) as AxiosResponse<ISearchResult>;

  if (selectedCoordinates.length === res.data.Total) return;

  const updatedParams = {
    aggregate_by: 'audit_status,registration_status',
    ...params,
    size: res.data.Total,
  };

  if (!res.data[chartsFields[0]] || !res.data[chartsFields[1]]) {
    yield call(setSearchChartsDataSaga, chartsFields, res.data);
  }

  const response = (yield call(
    apiToCall,
    updatedParams
  )) as AxiosResponse<ISearchResult>;

  yield call(updateMapSaga, response.data.companies);
  yield call(setSearchChartsDataSaga, chartsFields, response.data);
}

function* getSourceSearchMapInitialDataSaga() {
  try {
    const enterprise = (yield select(
      (state: RootState) => state.mainState.enterprise.uuid
    )) as string;

    const params: SourceSearchParams = {
      aggregate_by: 'service_type,certification_type',
      enterprise,
      is_registered: 0,
      audit_status: '2',
      size: 0,
    };

    yield call(getSearchMapInitialDataSaga, params, [
      'service_type_stats',
      'certification_type_stats',
    ]);

    yield put(getInitialSourceSearchChartsAndMapSlice.actions.success());
  } catch (error) {
    yield put(getInitialSourceSearchChartsAndMapSlice.actions.error(error));
  }
}

function* getSourceSearchResultsSaga({
  payload,
}: PayloadAction<SourceSearchParams>) {
  try {
    const { data } = (yield call(
      searchApi.getSearchSupplierResults,
      payload
    )) as AxiosResponse<ISearchResult>;

    yield put(getSourceSearchResultsSlice.actions.success(data));

    yield call(
      setSearchChartsDataSaga,
      ['service_type_stats', 'certification_type_stats'],
      data
    );

    yield call(updateMapSaga, data.companies, +(payload.from || '1'));
  } catch (error) {
    yield put(getSourceSearchResultsSlice.actions.error(error));
  }
}

function* getAuditSearchMapInitialDataSaga() {
  try {
    const enterprise = (yield select(
      (state: RootState) => state.mainState.enterprise.uuid
    )) as string;

    const params = {
      from: 0,
      size: 0,
      is_registered: 0,
      enterprise,
    };

    yield call(getSearchMapInitialDataSaga, params, [
      'audit_status_stats',
      'registration_status_stats',
    ]);

    yield put(getInitialAuditSearchChartsAndMapSlice.actions.success());
  } catch (error) {
    yield put(getInitialAuditSearchChartsAndMapSlice.actions.error(error));
  }
}

function* getAuditSearchResultsSaga({
  payload,
}: PayloadAction<SourceSearchParams>) {
  try {
    const { data } = (yield call(
      searchApi.getSearchSupplierResults,
      payload
    )) as AxiosResponse<ISearchResult>;

    yield put(getAuditSearchResultsSlice.actions.success(data));

    yield call(
      setSearchChartsDataSaga,
      ['audit_status_stats', 'registration_status_stats'],
      data
    );

    yield call(updateMapSaga, data.companies, +(payload.from || '1'));
  } catch (error) {
    yield put(getAuditSearchResultsSlice.actions.error(error));
  }
}

function* getGlobalSearchMapInitialDataSaga() {
  try {
    const params = {
      from: 0,
      size: 0,
      is_registered: 0,
    };

    yield call(
      getSearchMapInitialDataSaga,
      params,
      ['audit_status_stats', 'registration_status_stats'],
      searchApi.getGlobalSearchResults
    );

    yield put(getInitialGlobalSearchChartsAndMapSlice.actions.success());
  } catch (error) {
    yield put(getInitialGlobalSearchChartsAndMapSlice.actions.error(error));
  }
}

function* getGlobalSearchResultsSaga({
  payload,
}: PayloadAction<SourceSearchParams>) {
  try {
    const { data } = (yield call(
      searchApi.getGlobalSearchResults,
      payload
    )) as AxiosResponse<ISearchResult>;

    yield put(getGlobalSearchResultsSlice.actions.success(data));

    yield call(
      setSearchChartsDataSaga,
      ['audit_status_stats', 'registration_status_stats'],
      data
    );

    yield call(updateMapSaga, data.companies, +(payload.from || '1'));
  } catch (error) {
    yield put(getGlobalSearchResultsSlice.actions.error(error));
  }
}

export function* searchSagas() {
  yield takeEvery(
    getSourceSearchResultsSlice.actions.request,
    getSourceSearchResultsSaga
  );

  yield takeEvery(
    getInitialSourceSearchChartsAndMapSlice.actions.request,
    getSourceSearchMapInitialDataSaga
  );

  yield takeEvery(
    getAuditSearchResultsSlice.actions.request,
    getAuditSearchResultsSaga
  );

  yield takeEvery(
    getInitialAuditSearchChartsAndMapSlice.actions.request,
    getAuditSearchMapInitialDataSaga
  );

  yield takeEvery(
    getGlobalSearchResultsSlice.actions.request,
    getGlobalSearchResultsSaga
  );

  yield takeEvery(
    getInitialGlobalSearchChartsAndMapSlice.actions.request,
    getGlobalSearchMapInitialDataSaga
  );
}
