import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { store } from '@store';
import { sagas } from '@store/sagas';
import { sagaMiddleware } from '@common/store/middlewares';

import App from './App';
import './index.css';
import './ShortBootstrap.css';

sagaMiddleware.run(sagas);



ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);





