import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Enterprise,
  MainState,
  User,
  FullCatalog,
  SelectOption,
  Tracker,
  EnterpriseImage,
  UserSetting,
  PieChartItem,
} from '../../models/appLayout/appLayout.interface';

export interface SetEnterprisePayload {
  enterprise: Enterprise;
}
export interface SetEnterpriseAndUserPayload extends SetEnterprisePayload {
  user: User;
}

export interface SetEnterpriseAndTrackerPayload extends SetEnterprisePayload {
  tracker: Tracker;
}

const initialState: MainState = {
  audit_status_pie: [],
  certification_type_pie: [],
  registration_status_pie: [],
  service_type_pie: [],
  selectOptions: {
    annual_revenues: [],
    business_categories: [],
    certification_types: [],
    customer_bases: [],
    entity_types: [],
    ethnicity_types: [],
    naics_codes: [],
    number_of_employees: [],
    ownership_types: [],
    service_types: [],
    service_types_display: [],
    sic_codes: [],
    state_codes: [],
  },
  enterprise: {} as Enterprise,
  subscription: { search_level: 0 },
  user: {} as User,
  tracker: {
    enterprise_info: false,
    enterprise_location: false,
    enterprise_review: false,
    enterprise_service_product: false,
  },
  darkState: true,
};

export const mainStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setSelectOptions: (state, action: PayloadAction<FullCatalog>) => {
      state.selectOptions = action.payload;
    },
    setStateCode: (state, action: PayloadAction<SelectOption[]>) => {
      state.selectOptions.state_codes = action.payload;
    },
    setEnterpriseAndUser: (
      state,
      action: PayloadAction<SetEnterpriseAndUserPayload>
    ) => {
      state.enterprise = action.payload.enterprise;
      state.user = action.payload.user;
    },
    setEnterpriseAndTracker: (
      state,
      action: PayloadAction<SetEnterpriseAndTrackerPayload>
    ) => {
      state.enterprise = action.payload.enterprise;
      state.tracker = action.payload.tracker;
    },
    setEnterpriseImage: (state, action: PayloadAction<EnterpriseImage>) => {
      if (state.enterprise !== undefined) {
        state.enterprise.image = action.payload;
      }
    },
    setUserImage: (state, action: PayloadAction<EnterpriseImage>) => {
      if (state.user !== undefined) {
        state.user.image = action.payload;
      }
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setTracker: (state, action: PayloadAction<Tracker>) => {
      state.tracker = action.payload;
    },
    setUserSetting: (state, action: PayloadAction<UserSetting>) => {
      if (state.user !== undefined) {
        state.user.user_setting = action.payload;
      }
    },
    setDarkState: (state, action: PayloadAction<boolean>) => {
      state.darkState = action.payload;
    },
    setAuditStatusPie: (state, action: PayloadAction<PieChartItem[]>) => {
      state.audit_status_pie = action.payload;
    },
    setRegistrationStatusPie: (
      state,
      action: PayloadAction<PieChartItem[]>
    ) => {
      state.registration_status_pie = action.payload;
    },
    setServiceTypePie: (state, action: PayloadAction<PieChartItem[]>) => {
      state.service_type_pie = action.payload;
    },
    setCertificationTypePie: (state, action: PayloadAction<PieChartItem[]>) => {
      state.certification_type_pie = action.payload;
    },
  },
});

export const {
  setSelectOptions,
  setStateCode,
  setUserSetting,
  setEnterpriseAndUser,
  setEnterpriseAndTracker,
  setTracker,
  setEnterpriseImage,
  setUserImage,
  setUser,
  setDarkState,
  setAuditStatusPie,
  setRegistrationStatusPie,
  setServiceTypePie,
  setCertificationTypePie,
} = mainStateSlice.actions;

export const mainStateReducer = mainStateSlice.reducer;
